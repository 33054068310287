<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                dynamicComponent: null,
                isPeugeot: false,
            };
        },

        watch: {
            name: {
                handler() {
                    this.loadDynamicComponent();
                    this.checkIsPeugeot();
                },
                immediate: true,
            },
        },

        methods: {
            loadDynamicComponent() {
                const name = this.name;

                require.ensure([], (require) => {
                    this.dynamicComponent = require(`@/assets/images/svg/${name}.vue`).default;
                });
            },

            checkIsPeugeot() {
                this.isPeugeot = this.name === 'peugeot';
            },
        },
    };
</script>

<template>
    <component :is="dynamicComponent" v-if="dynamicComponent" class="svg-component" :class="{ 'no-dark-style': isPeugeot }" />
</template>

<style lang="scss">
    .dark-layout .svg-component:not(.no-dark-style) {
        path {
            fill: #4b465c;
        }
    }
</style>
