<template>
    <div class="container">
        <span class="title">Historique des campagnes</span>

        <div class="filters-selection">
            <div class="filters">
                <div class="select-group">
                    <span style="font-size: 13px">Période :</span>

                    <div style="min-width: 240px">
                        <b-form-datepicker no-flip v-model="startDate" placeholder="Date de début"></b-form-datepicker>
                    </div>
                    <div style="min-width: 240px">
                        <b-form-datepicker no-flip v-model="endDate" :min="minEndDate" placeholder="Date de fin"></b-form-datepicker>
                    </div>
                </div>

                <div class="select-group">
                    <b-form-group label-for="input-name">
                        <b-form-input
                            :disabled="archivedCampaigns === undefined"
                            placeholder="Nom de la campagne"
                            v-model="searchByName"
                            id="input-name"
                            debounce="300"
                            style="border-radius: 6px"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="filters">
                <b-form-group class="custom-form-group" label-for="input-code">
                    <div class="select-group">
                        <label>Point de vente :</label>
                        <b-form-input
                            :disabled="archivedCampaigns === undefined"
                            placeholder="Code, nom point de vente"
                            label="Point de vente :"
                            id="input-code"
                            v-model="searchPos"
                            debounce="300"
                            style="border-radius: 6px"
                        ></b-form-input>
                    </div>
                </b-form-group>

                <div>
                    <label style="font-weight: 400">Filtrer par :</label>
                    <b-dropdown text="Marque" id="dropdown-filter" :disabled="archivedCampaigns === undefined" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="media-dropdown">
                        <template #button-content>
                            <div style="display: flex; justify-content: space-between; align-items: center">
                                Marque {{ filterByBrand.length > 0 ? `(${filterByBrand.length})` : '' }}
                                <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                            </div>
                        </template>
                        <b-dropdown-form style="width: 190px; max-width: 190px">
                            <b-form-checkbox-group v-model="filterByBrand" stacked size="m" :options="sortedBrands"></b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>
                </div>

                <!-- <b-form-group class="custom-form-group" label-for="input-media">
                    <div class="select-group">
                        <label style="font-size: 11px">Média :</label>

                        <div>
                            <b-form-select label="media" id="input-media" v-model="searchByMedia" debounce="300" style="border-radius: 6px; min-width: 140px" :options="sortedMedias"></b-form-select>
                        </div>
                    </div>
                </b-form-group> -->

                <div>
                    <b-dropdown text="Status" id="dropdown-filter" :disabled="archivedCampaigns === undefined" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="media-dropdown">
                        <template #button-content>
                            <div style="display: flex; justify-content: space-between; align-items: center">
                                Statut {{ searchByStatus.length > 0 ? `(${searchByStatus.length})` : '' }}
                                <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                            </div>
                        </template>
                        <b-dropdown-form style="width: 190px; max-width: 190px">
                            <b-form-checkbox-group v-model="searchByStatus" stacked size="m">
                                <b-form-checkbox v-for="option in statusOptions" :key="option.value" :value="option.value">
                                    {{ option.label }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>
                </div>
            </div>

            <div class="btn" :style="{ 'pointer-events': isDisabled ? 'none' : 'auto' }">
                <b-button :disabled="isDisabled" variant="primary" @click="getArchivedCampaigns(startDate, endDate)">Rechercher</b-button>
            </div>
        </div>

        <div v-if="archivedCampaigns !== undefined && filteredArchivedCampaigns.length > 0" style="display: flex; gap: 10px; align-items: center; justify-content: flex-end; margin-right: 50px">
            <label style="font-size: 13px">Trier par :</label>
            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" style="width: 100px"></b-form-select>
        </div>
        <div class="mt-3">
            <b-table v-if="archivedCampaigns !== undefined" :items="filteredArchivedCampaigns" :fields="fields" :current-page="currentPage" :per-page="perPage" striped show-empty>
                <template #cell(media)="row">
                    <div>
                        <div style="padding-left: 2rem">{{ row.item.details.label_quotation ? row.item.details.label_quotation : '' }} | {{ row.item.point_code ? row.item.point_code : '' }}</div>
                    </div>
                </template>

                <template #cell(brand)="row">
                    {{ row.item.brand.toUpperCase() }}
                </template>

                <template #cell(status)="row">
                    {{ row.item.status === 30 ? 'Archivée' : row.item.status === 99 ? 'Annulée' : row.item.status }}
                </template>

                <template #cell(startDate)="row">
                    {{ row.item.start_date }}
                </template>

                <template #cell(endDate)="row">
                    {{ row.item.end_date }}
                </template>

                <template #cell(downloadReport)="row">
                    <div style="display: flex; justify-content: center">
                        <b-button @click="downloadReport(row.item)" variant="primary" class="btn-icon" size="sm" data-toggle="tooltip" data-placement="top" title="Télécharger le bilan">
                            <feather-icon icon="DownloadIcon" />
                        </b-button>
                    </div>
                </template>

                <template #empty="scope">
                    <b-skeleton-table :columns="1" :hide-header="true" class="skeleton" v-if="isLoading"></b-skeleton-table>
                    <p style="text-align: center; margin-top: 1rem">Pas des campagnes trouvées.</p>
                </template>

                <template #emptyfiltered="scope">
                    <p style="text-align: center; margin-top: 1rem">Pas des campagnes trouvées.</p>
                </template>
            </b-table>

            <b-row v-if="archivedCampaigns !== undefined && filteredArchivedCampaigns.length > 0" class="flex justify-content-between align-items-baseline py-0 px-3">
                <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
                <div>
                    Showing 1 to {{ perPage }} of {{ filteredArchivedCampaigns.length }}
                    {{ filteredArchivedCampaigns.length === 1 ? 'campaign' : filteredArchivedCampaigns.length === 0 ? 'campaign' : 'campaigns' }}
                </div>
            </b-row>
        </div>
    </div>
</template>

<script>
    import http from '@/helpers/http';
    import FileSaver from 'file-saver';
    import { mapGetters } from 'vuex';

    export default {
        name: 'archived',

        data() {
            return {
                searchByName: '',
                searchPos: '',
                searchByBrand: '',
                searchByMedia: '',
                searchByStatus: [],
                startDate: null,
                endDate: null,
                currentPage: 1,
                perPage: 25,
                pageOptions: [25, 50, 100],
                rows: 0,

                statusOptions: [
                    { text: '30', value: '30', label: 'Archivée' },
                    { text: '99', value: '99', label: 'Annulée' },
                ],
                archivedCampaigns: undefined,
                isLoading: false,

                fields: [
                    {
                        key: 'media',
                        label: 'campagnes',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { width: '40%' },
                    },
                    {
                        key: 'brand',
                        label: 'Marque',
                        sortable: true,
                        class: 'text-center',
                    },

                    {
                        key: 'status',
                        label: 'statut',
                        sortable: true,
                        sortDirection: 'desc',
                        class: 'text-center',
                    },
                    {
                        key: 'startDate',
                        label: 'date de debut',
                        sortable: true,
                        class: 'text-center',
                    },
                    {
                        key: 'endDate',
                        label: 'date de fin',
                        sortable: true,
                        class: 'text-center',
                    },

                    {
                        key: 'downloadReport',
                        label: '',
                        sortable: true,
                        class: 'text-center',
                    },
                ],

                filterByBrand: [],
            };
        },

        computed: {
            ...mapGetters({
                uniquesBrandsCampaigns: 'app/getBrandsCampaigns',
                uniquesBrandsMedias: 'app/getBrandsMedias',
                capitalizedMedias: 'app/getMediasList',
            }),

            filteredArchivedCampaigns() {
                let filteredCampaigns = this.archivedCampaigns;

                if (this.searchByStatus.length > 0) {
                    if (this.searchByBrand != '') {
                        filteredCampaigns = this.archivedCampaigns.filter(
                            (item) => item.brand.toLowerCase() === this.searchByBrand.toLowerCase() && this.searchByStatus.includes(item.status.toString())
                        );
                    } else {
                        filteredCampaigns = this.archivedCampaigns.filter((item) => this.searchByStatus.includes(item.status.toString()));
                    }
                }

                if (this.searchByBrand != '') {
                    filteredCampaigns = this.archivedCampaigns.filter((item) => item.brand.toLowerCase() === this.searchByBrand.toLowerCase());
                }

                if (this.searchByName) {
                    filteredCampaigns = filteredCampaigns.filter((item) => {
                        if (item.details.label_quotation) {
                            return item.details.label_quotation.toLowerCase().includes(this.searchByName.toLowerCase());
                        }
                    });
                }

                if (this.searchPos) {
                    filteredCampaigns = filteredCampaigns.filter((item) => {
                        if (item.details.label_quotation) {
                            return item.point_code.toLowerCase().includes(this.searchPos.toLowerCase());
                        }
                    });
                }

                if (this.filterByBrand.length > 0) {
                    const lowercasedSort = this.filterByBrand.map((media) => media.toLowerCase());

                    filteredCampaigns = filteredCampaigns.filter((media) => lowercasedSort.includes(media?.brand_label?.toLowerCase()));
                }

                this.rows = filteredCampaigns.length;
                return filteredCampaigns;
            },

            isDisabled() {
                return this.startDate === null || this.endDate === null;
            },
            maxStartDate() {
                if (this.endDate === null) {
                    return undefined;
                }

                const maxStartDate = new Date(this.endDate);
                maxStartDate.setMonth(maxStartDate.getMonth() - 1);
                maxStartDate.setDate(maxStartDate.getDate() - 1);

                return maxStartDate;
            },
            minEndDate() {
                if (this.startDate === null) {
                    return undefined;
                }

                const minEndDate = new Date(this.startDate);
                minEndDate.setMonth(minEndDate.getMonth());
                minEndDate.setDate(minEndDate.getDate() + 1);
                return minEndDate;
            },

            sortedBrands() {
                let uniqueBrands = [];

                uniqueBrands = this.uniquesBrandsCampaigns;

                // Filtrer pour obtenir des éléments uniques par clé
                let uniqueBrandsMap = {};
                uniqueBrands.forEach((brand) => {
                    if (brand.label !== undefined && brand.label !== 'lmp') {
                        uniqueBrandsMap[brand.key] = brand;
                    }
                });

                // Convertir l'objet en tableau, trier par brand_key, et retourner les labels
                return Object.values(uniqueBrandsMap)
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((brand) => brand.label);
            },

            sortedMedias() {
                let uniquesMediasMap = {};
                this.capitalizedMedias.forEach((media) => {
                    if (media.label) {
                        uniquesMediasMap[media.key] = media;
                    }
                });

                return Object.values(uniquesMediasMap)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((media) => media.label);
            },
        },

        methods: {
            async getArchivedCampaigns(startDate, endDate) {
                this.isLoading = true;
                try {
                    this.$root.toggleGlobalLoading(true);

                    const response = await this.$store.dispatch('app/getArchivedCampaigns', { startDate, endDate });

                    this.archivedCampaigns = response.data;
                    this.isLoading = false;
                    this.$root.toggleGlobalLoading(false);
                } catch (error) {
                    console.error('Error:', error);
                    this.isLoading = false;
                }
            },

            downloadReport(data) {
                const campaignCode = data.campaign_code;

                const pointText = data.details.point_text[0].replaceAll(' ', '-');

                const startDate = data.start_date.replaceAll('/', '-');
                const brand = campaignCode.split('_')[1].toLowerCase();

                this.$root.toggleGlobalLoading(true);
                http.getPDF(this.$store.state.app.apiCampaignUrl + '/api/v1/' + brand + '/campaign/' + campaignCode + '/metrics', localStorage.getItem('accessToken'))
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        if (res[0].data.byteLength > 0) {
                            const blob = new Blob([res[0].data], {
                                type: res[0].headers['content-type'],
                            });

                            FileSaver.saveAs(blob, pointText + '_' + startDate + '_bilan.pdf');
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Le rapport a été téléchargé',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        } else {
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Pas de rapport à télécharger',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la génération du rapport',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    .card-body {
        padding: 16px 24px 0px 24px;
    }

    .container {
        margin: 0;
        min-width: 100%;
        padding-left: 24px;

        .title {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        .filters-selection {
            padding-top: 16px;
        }

        .filters {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: 40px;

            #dropdown {
                width: 224px;
                height: 32px;
            }

            .label {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }

            .select-group {
                display: flex;
                align-items: baseline;
                gap: 10px;
            }
        }

        .btn {
            display: flex;
            justify-content: center;
            padding: 16px 24px;
        }

        .custom-form-group {
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        .btn {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.43px;
            padding: 10px 20px;
        }
    }

    .form-group.custom-form-group {
        gap: 10px;
    }
</style>
<style lang="scss">
    .b-form-datepicker div[aria-disabled='true'] {
        cursor: not-allowed;
    }
</style>
