<template>
    <div>
        <!-- Change provider id modal -->
        <generic-confirm-modal
            ref="modal-provider-id"
            :title="providerId ? 'Modification de l\'id du média' : currentTable === 2 ? 'Modification de l\'id du média' : 'Création de l\'id du média'"
            cancel-title="Annuler"
            :ok-title="providerId ? 'Modifier' : 'Créer'"
            @on-accept="changeProviderId"
            @on-cancel="closeChangeProviderId"
        >
            <div v-if="currentTable === 1">
                <b-input id="input-provider-id" v-model="providerId"></b-input>
            </div>
            <div v-else>
                <div v-for="media in currentMedias" style="display: flex; flex-direction: column; gap: 10px">
                    <span style="margin-top: 15px">{{ media.details.media_category }}</span>
                    <b-input id="input-provider-id" v-model="media.details.provider_data.id"></b-input>
                </div>
            </div>
        </generic-confirm-modal>

        <!-- Cancel unstarted campaign modal -->
        <generic-confirm-modal
            ref="modal-cancel-campaign"
            title="Etes-vous sûre de vouloir annuler cette campagne ?"
            cancel-title="Non"
            ok-title="Oui"
            @on-accept="cancelCampaign"
            @on-cancel="$refs['modal-cancel-campaign'].close()"
        >
        </generic-confirm-modal>

        <generic-confirm-modal ref="modal-media-link" title="Lier le média" cancel-title="Annuler" ok-title="Lier" @on-accept="onSubmit" @on-cancel="$refs['modal-media-link'].close()">
            <b-form @submit.stop.prevent="">
                <b-form-group :label="`Marque`" label-for="select-brand">
                    <b-input id="select-brand" v-model="brand" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Point de vente`" label-for="select-pos">
                    <b-input id="select-pos" v-model="salePoint" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Code de point de vente`" label-for="select-pos-code">
                    <b-input id="select-pos-code" v-model="salePointCode" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Média categorie`" label-for="select-media">
                    <b-input id="select-media" v-model="media" disabled></b-input>
                </b-form-group>
                <b-form-group label="DSP" label-for="select-dsp">
                    <b-form-select id="select-dsp" v-model="selectedDsp" :options="dspOptions" text-field="name" value-field="value" :state="isValidDsp"></b-form-select>
                    <b-form-invalid-feedback :state="isValidDsp">Un DSP doit être sélectionné</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="ID" label-for="select-id">
                    <b-input id="selecte-id" v-model="id" :state="isValidId" />
                    <b-form-invalid-feedback :state="isValidId">Une ID doit être saisie</b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </generic-confirm-modal>

        <b-form
            v-if="!showArchived && !statusFilters.includes('999')"
            inline
            class="justify-content-between mb-1 px-2"
            @keydown.enter.prevent
            style="align-items: baseline; display: flex; width: 100%"
        >
            <div style="width: 100%">
                <div class="d-flex" style="width: 100%; justify-content: space-between">
                    <b-form-input
                        v-model="searchFilter"
                        :placeholder="currentTableFields[0].key === 'medias' ? 'Rechercher un média' : 'Rechercher une campagne'"
                        debounce="300"
                        style="border-radius: 6px"
                    ></b-form-input>
                    <div v-if="!statusFilters.includes('999')" class="d-flex filter">
                        <label>Filtrer par :</label>

                        <b-dropdown
                            v-if="currentTableFields[0].key === 'medias' && !statusFilters.includes('999')"
                            text="Média"
                            id="dropdown-filter"
                            checkbox-menu
                            allow-focus
                            no-flip
                            ref="dropdown"
                            size="sm"
                            class="media-dropdown"
                        >
                            <template #button-content>
                                <div style="display: flex; justify-content: space-between; align-items: center">
                                    Média {{ filterByMedia.length > 0 ? `(${filterByMedia.length})` : '' }}
                                    <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                                </div>
                            </template>

                            <b-dropdown-form>
                                <b-form-checkbox-group v-model="filterByMedia" :options="sortedMedias"></b-form-checkbox-group>
                            </b-dropdown-form>
                        </b-dropdown>

                        <b-dropdown text="Marque" id="dropdown-filter" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="media-dropdown">
                            <template #button-content>
                                <div style="display: flex; justify-content: space-between; align-items: center">
                                    Marque {{ filterByBrand.length > 0 ? `(${filterByBrand.length})` : '' }}
                                    <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                                </div>
                            </template>
                            <b-dropdown-form style="width: 190px; max-width: 190px">
                                <b-form-checkbox-group v-model="filterByBrand" stacked size="m" :options="sortedBrands"></b-form-checkbox-group>
                            </b-dropdown-form>
                        </b-dropdown>
                    </div>
                    <b-form-group v-if="statusFilters.includes('8')" style="line-height: inherit">
                        <b-form-checkbox-group class="checkbox-group-custom" id="checkbox-group-1" v-model="statusSubFilters" :options="statusOptions" name="status"></b-form-checkbox-group>
                    </b-form-group>

                    <div v-if="!statusFilters.includes('999')" class="d-flex">
                        <b-form-group label="Trier par : " label-for="start-date-select" content-cols-lg="3" label-cols-lg="4" class="custom-label">
                            <b-form-select id="start-date-select" v-model="sortBy" :options="dateSortOptions" style="width: 155px"></b-form-select>
                        </b-form-group>
                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" style="margin-left: 1rem"></b-form-select>
                    </div>
                </div>
            </div>
        </b-form>

        <TableContent
            :class="{ 'alerts-container no-thead': currentTable === 3 }"
            :parent-sort-by="sortBy"
            v-if="!showArchived"
            :search-filter="searchFilter"
            :tables="tables"
            :current-table="currentTable"
            :status-filters="statusFilters"
            :status-sub-filters="statusSubFilters"
            :filter-by-media="filterByMedia"
            :filter-by-brand="filterByBrand"
            :campaigns="campaigns"
            :medias="medias"
            :alerts="alerts"
            :current-table-fields="currentTableFields"
            :per-page="perPage"
            :current-page="currentPage"
            :right="right"
            :is-loading="isLoading"
            @handle-dsp="linkProvider"
            @creation-provider="providerMediaCreation"
            @creation-facebook="providerFacebookCreation"
            @add-theme="addThemeToCampaign"
            @change-media-id="openChangeMediaId"
            @update-date="updateMediaDates"
            @update-camp-date="updateDatesCamp"
            @sync-metrics="syncMetrics"
            @force-metrics="forceMetrics"
            @cancel-camp="cancelCampaignAction"
            @download-report="downloadReport"
            @open-alert="onOpenAlert"
            @tableDataLength="handleTableDataLength"
            @refresh="$emit('on-refresh')"
            @updateStatusFilters="updateStatusFilters"
        />

        <div v-else="showArchived">
            <archived-campaigns-component />
        </div>

        <b-row v-if="!showArchived" class="flex justify-content-between align-items-baseline py-0 px-3">
            <b-pagination class="mt-3" v-model="currentPage" :total-rows="tableDataLength" :per-page="perPage" first-number></b-pagination>

            <div v-if="currentTable === 3">
                Showing 1 to {{ perPage < alerts.length ? perPage : alerts.length }} of {{ alerts.length }}
                {{ tableDataLength === 1 ? 'campaign' : tableDataLength === 0 ? 'campaign' : 'campaigns' }}
            </div>

            <div v-if="tableDataLength != 0 && currentTable !== 3">
                Showing 1 to {{ perPage < tableDataLength ? perPage : tableDataLength }} of {{ tableDataLength }}
                {{ tableDataLength === 1 ? 'campaign' : tableDataLength === 0 ? 'campaign' : 'campaigns' }}
            </div>
        </b-row>

        <update-date-modal ref="update-date-modal" @on-update-date-campaign="updateDateCampaign"></update-date-modal>
        <Add-campaign-modal ref="add-campaign-modal" @on-add-campaign="addThemeToCampaign"></Add-campaign-modal>
        <Create-provider-campaign-modal ref="create-provider-campaign-modal"></Create-provider-campaign-modal>
        <Create-provider-media-modal ref="create-provider-media-modal"></Create-provider-media-modal>
        <Create-provider-facebook-modal ref="create-provider-facebook-modal"></Create-provider-facebook-modal>
    </div>
</template>

<script>
    import http from '@/helpers/http';
    import axios from 'axios';
    import FileSaver from 'file-saver';
    import UpdateDateModal from './UpdateDateModal/update-date-modal.vue';
    import AddCampaignModal from './UpdateDateModal/add-theme-to-campaign-modal.vue';
    import CreateProviderCampaignModal from '../LinkCampaigns/create-provider-campaign-modal.vue';
    import CreateProviderMediaModal from '../LinkCampaigns/create-provider-media-modal.vue';
    import CreateProviderFacebookModal from '../LinkCampaigns/create-provider-facebook-modal.vue';

    import { mapGetters } from 'vuex';
    import ArchivedCampaignsComponent from './ArchivedCampaignsComponent.vue';
    import TableContent from './TableContent.vue';
    import { BTable, BFormInput, BCollapse } from 'bootstrap-vue';

    const moment = require('moment');

    export default {
        components: {
            UpdateDateModal,
            AddCampaignModal,
            CreateProviderCampaignModal,
            CreateProviderMediaModal,
            CreateProviderFacebookModal,
            BTable,
            BFormInput,
            BCollapse,
            TableContent,
            ArchivedCampaignsComponent,
        },
        props: {
            campaigns: {
                type: Array,
                required: true,
            },
            filterByAlertType: {
                type: Array,
                required: false,
            },

            filterByMediaAlert: {
                type: Array,
                required: false,
            },

            right: {
                type: String,
                required: true,
            },

            medias: {
                type: Array,
                required: true,
            },

            alerts: {
                type: Array,
                required: true,
            },

            archivedCampaigns: {
                type: Array,
                default: () => [],
            },

            isLoading: {
                type: Boolean,
                required: true,
            },

            alertTopTenCount: {
                type: Number,
                required: true,
            },

            alertReportVigilantCount: {
                type: Number,
                required: true,
            },

            alertReportCriticalCount: {
                type: Number,
                required: true,
            },
            currentTable: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                statusOptions: [
                    { text: 'A valider CSM', value: 'csm' },
                    { text: 'A valider Direction', value: 'direction' },
                ],
                providerId: null,
                providerInput: {},
                currentCampaignCode: null,
                globalDone: [],
                globalTopTen: [],
                globalMedias: [],
                globalDays: 0,
                globalClicks: 0,
                globalImpressions: 0,
                currentPage: 1,
                searchFilter: '',

                rows: 0,
                perPage: 25,
                pageOptions: [25, 50, 100],

                dateSort: 'Date de début',
                filterByBrand: [],
                filterByMedia: [],
                sortBy: 'startDateMedia',

                statusSubFilters: [],
                dropdown: [],

                selectedCampaign: null,
                brand: null,
                media: null,
                currentMedias: [],
                salePoint: null,
                salePointCode: null,
                selectedDsp: '',
                dspOptions: [],
                isValidDsp: null,
                id: '',
                isValidId: null,
                renderErrors: false,
                reassignLever: false,
                selected: null,
                showArchived: false,

                sort: '',
                sortDirection: 'asc',

                tableDataLength: 0,
                parentData: {},

                alertType: '',
                alertCode: '',

                alertsCount: {
                    topTen: [],
                    critical: {
                        report: [],
                        csm: [],
                        direction: [],
                    },
                    vigilant: {
                        report: [],
                        csm: [],
                        direction: [],
                    },
                },

                processedAlerts: new Set(),
                mediasMap: {
                    FBK: 'Facebook',
                    FBF: 'Facebook',
                    YOU: 'Youtube',
                    VID: 'Vidéo',
                    DSK: 'Ordinateur',
                    NTV: 'Native',
                    LIN: 'Linkedin',
                    ADW: 'Adwords',
                    MOB: 'Mobile',
                    AWR: 'Adwords Fil Rouge',
                },
            };
        },

        watch: {
            selectedDsp(newValue) {
                if (newValue) {
                    this.isValidDsp = true;
                }

                if (this.selectedDsp === null || this.selectedDsp === '' || this.selectedDsp === 'wellpack') {
                    this.isValidDsp = null;
                }
            },

            async statusSubFilters(subfilters) {
                await this.$store.dispatch('app/updateStatusSubFilter', subfilters);
            },

            id(newValue) {
                if (newValue) {
                    this.isValidId = true;
                }

                if (this.id === null || this.id === undefined || !this.id) {
                    this.isValidId = null;
                }
            },

            alerts: {
                immediate: true,
                handler() {
                    for (const alert of this.campaignsAlerts) {
                        if (!this.processedAlerts.has(alert)) {
                            switch (alert.type) {
                                case 'top_10':
                                    this.alertsCount.topTen.push(alert);
                                    break;

                                case 'report_vigilant':
                                    this.alertsCount.vigilant.report.push(alert);
                                    break;
                                case 'report_vigilant_csm':
                                    this.alertsCount.vigilant.csm.push(alert);
                                    break;
                                case 'report_vigilant_direction':
                                    this.alertsCount.vigilant.direction.push(alert);
                                    break;
                                case 'report_critical':
                                    this.alertsCount.critical.report.push(alert);
                                    break;
                                case 'report_critical_csm':
                                    this.alertsCount.critical.csm.push(alert);
                                    break;
                                case 'report_critical_direction':
                                    this.alertsCount.critical.direction.push(alert);
                                    break;

                                default:
                                    break;
                            }
                            // Ajouter l'alerte au set des alertes traitées
                            this.processedAlerts.add(alert);
                        }
                    }
                },
            },

            statusFilters() {
                if (this.statusFilters.includes('30')) {
                    return (this.showArchived = true);
                } else this.showArchived = false;
            },

            currentTable: {
                handler(newValue, oldValue) {
                    if (oldValue !== newValue) {
                        this.filterByMedia = [];
                        this.filterByBrand = [];
                        this.searchFilter = '';
                        this.sortBy = newValue === 1 ? 'startDateMedia' : 'startDateCampaign';
                    }
                },
            },

            currentPage(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'currentPage', value });
            },
            filterByBrand(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'brandFilter', value });
            },
            filterByMedia(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'mediaFilter', value });
            },
            searchFilter(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'searchFilter', value });
            },
            sortBy(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'sortBy', value });
            },
            globalConfigPageCampaigns: {
                immediate: true,
                deep: true,
                handler() {
                    if (this.currentTable !== 3) {
                        this.currentPage = this.globalConfigPageCampaigns?.currentPage;
                        this.filterByBrand = this.globalConfigPageCampaigns.brandFilter;
                        this.filterByMedia = this.globalConfigPageCampaigns?.mediaFilter;
                        this.searchFilter = this.globalConfigPageCampaigns?.searchFilter;
                        this.sortBy = this.globalConfigPageCampaigns?.sortBy;
                    }
                },
            },
        },

        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
                formatToDashDate: 'app/formatToDashDate',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
                statusFilters: 'app/getStatusFilters',
                statusSubFilter: 'app/getStatusSubFilter',
                capitalizedMedias: 'app/getMediasList',
                mediasList: 'app/getCurrentMediasList',
                allCampaigns: 'app/getCurrentCampignsList',
                campaignsAlerts: 'app/getGlobalAlerts',
                groupedAlerts: 'app/getGroupedAlerts',
                alertTypes: 'app/getAlertsTypes',
                globalConfigPageCampaigns: 'app/getGlobalConfigPageCampaigns',
                uniquesBrandsMedias: 'app/getBrandsMedias',
                uniquesBrandsCampaigns: 'app/getBrandsCampaigns',
            }),

            sortedBrands() {
                let uniqueBrands = [];

                this.currentTableFields[0].key === 'medias' ? (uniqueBrands = this.uniquesBrandsMedias) : (uniqueBrands = this.uniquesBrandsCampaigns);

                // Filtrer pour obtenir des éléments uniques par clé
                let uniqueBrandsMap = {};
                uniqueBrands.forEach((brand) => {
                    if (brand.label !== undefined && brand.label !== 'lmp') {
                        uniqueBrandsMap[brand.key] = brand;
                    }
                });

                // Convertir l'objet en tableau, trier par brand_key, et retourner les labels
                return Object.values(uniqueBrandsMap)
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((brand) => brand.label);
            },

            sortedMedias() {
                let uniquesMediasMap = {};
                this.capitalizedMedias.forEach((media) => {
                    if (media.label) {
                        uniquesMediasMap[media.key] = media;
                    }
                });

                return Object.values(uniquesMediasMap)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((media) => media.label);
            },

            globalAlerts() {
                let groupedByType;

                const alerts = [
                    this.alertsCount.topTen.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('top_10')) && {
                            alerts: this.alertsCount.topTen,
                            label: this.alertsCount.topTen.length > 1 ? 'Médias erreurs remontée Top ten' : 'Média erreurs remontée Top ten',
                            type: 'globalAlert',
                            isDanger: true,
                            category: 'top_10',
                            modalTopTenLabel: this.alertsCount.topTen.length > 1 ? 'Erreurs de remontée Top ten' : 'Erreur de remontée Top ten',
                        },

                    this.alertsCount.critical.report.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.critical.report,
                            label: this.alertsCount.critical.report.length > 1 ? 'Bilans en retard' : 'Bilan en retard',
                            type: 'globalAlert',
                            category: 'report_critical',
                            isDanger: true,
                        },

                    this.alertsCount.critical.csm.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.critical.csm,
                            label: this.alertsCount.critical.csm.length > 1 ? 'Validations CSM en retard' : 'Validation CSM en retard',
                            type: 'globalAlert',
                            category: 'report_critical_csm',
                            isDanger: true,
                        },

                    this.alertsCount.critical.direction.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.critical.direction,
                            label: this.alertsCount.critical.direction.length > 1 ? 'Validations Direction en retard' : 'Validation Direction en retard',
                            type: 'globalAlert',
                            category: 'report_critical_direction',
                            isDanger: true,
                            isLast: true,
                        },

                    this.alertsCount.vigilant.report.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.vigilant.report,
                            label: this.alertsCount.vigilant.report.length > 1 ? 'Bilans en retard' : 'Bilan en retard',
                            type: 'globalAlert',
                            category: 'report_vigilant',
                            isDanger: false,
                        },

                    this.alertsCount.vigilant.csm.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.vigilant.csm,
                            label: this.alertsCount.vigilant.csm.length > 1 ? 'Validations CSM en retard' : 'Validation CSM en retard',
                            type: 'globalAlert',
                            category: 'report_vigilant_csm',
                            isDanger: false,
                        },

                    this.alertsCount.vigilant.direction.length > 0 &&
                        (this.filterByAlertType.length === 0 || this.filterByAlertType.includes('report')) && {
                            alerts: this.alertsCount.vigilant.direction,
                            label: this.alertsCount.vigilant.direction.length > 1 ? 'Validations Direction en retard' : 'Validation Direction en retard',
                            type: 'globalAlert',
                            category: 'report_vigilant_direction',
                            isDanger: false,
                        },
                ];

                groupedByType = alerts.filter((alert) => alert);
                this.$store.dispatch('app/getGroupedAlerts', groupedByType);

                return groupedByType;
            },

            dateSortOptions() {
                if (this.currentTableFields[0].key === 'campaigns') {
                    return [
                        { text: '', value: '', disabled: false },
                        { text: 'Date de début', value: 'startDateCampaign', disabled: false },
                        { text: 'Date de fin', value: 'endDateCampaign', disabled: false },
                    ];
                }

                return [
                    { text: '', value: '', disabled: false },
                    { text: 'Date de début', value: 'startDateMedia', disabled: false },
                    { text: 'Date de fin', value: 'endDateMedia', disabled: false },
                ];
            },

            currentTableFields() {
                return this.tables[this.currentTable].fields;
            },

            tables() {
                return {
                    1: {
                        data: this.medias,
                        fields: [
                            {
                                key: 'medias',
                                label: 'MÉDIAS',
                                thStyle: { width: '25%' },
                                class: 'media-content',
                            },

                            {
                                key: 'marque',
                                label: 'marques',
                                thStyle: { width: '11%', textAlign: 'right' },
                            },
                            {
                                key: 'startDateMedia',
                                label: 'Date de début',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '16%', textAlign: 'right' },
                            },
                            {
                                key: 'endDateMedia',
                                label: 'Date de fin',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '12%', textAlign: 'right' },
                            },
                            {
                                key: 'nb_metrics',
                                label: 'Metrics / jour',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '9%', textAlign: 'right' },
                            },
                            {
                                key: 'ratio',
                                label: 'Objectif',
                                tdClass: 'objective-cell',

                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '18%', textAlign: 'right' },
                            },
                            {
                                key: 'actionsMedias',
                                label: 'Actions',
                                sortable: false,
                                thStyle: { width: '11%', textAlign: 'right' },
                            },
                        ],
                    },
                    2: {
                        data: this.campaigns,
                        fields: [
                            {
                                key: 'campaigns',
                                label: 'Campagnes',
                                class: 'media-content',
                                thStyle: { width: '25%' },
                            },
                            {
                                key: 'children',
                                label: 'medias',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '11%', textAlign: 'right' },
                            },

                            {
                                key: 'start_date',
                                label: 'Date de début',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '8%', textAlign: 'right' },
                            },
                            {
                                key: 'endDateCampaign',
                                label: 'Date de fin',
                                tdAttr: this.highlightDateReached,
                                sortable: true,
                                thStyle: { width: '8%', textAlign: 'right' },
                            },
                            {
                                key: 'budget',
                                label: 'Budget',
                                sortable: true,
                                thStyle: { width: '12%', textAlign: 'right' },
                            },

                            {
                                key: 'paid',
                                label: 'Payé',
                                tdClass: 'cell-status',
                                sortable: true,
                                thStyle: { width: '9%', textAlign: 'right' },
                            },
                            {
                                key: 'left_to_pay',
                                label: 'Reste à payer',
                                sortable: true,
                                thStyle: { width: '16%', textAlign: 'right' },
                            },
                            {
                                key: 'actionsCampaigns',
                                label: 'Actions',
                                sortable: false,
                                thStyle: { width: '11%', textAlign: 'right' },
                            },
                        ],
                    },
                    3: {
                        data: this.alerts.concat(this.globalAlerts),

                        fields: [
                            {
                                key: 'campaignsAlerts',
                                label: '',
                                thStyle: { width: '25%' },
                                sortable: false,
                                class: 'media-content alerts-content',
                            },

                            {
                                key: 'marqueCampAlerts',
                                label: '',
                                sortable: false,
                                class: 'brand-alert',
                            },
                            {
                                key: 'startDateCampAlert',
                                label: '',
                                tdAttr: this.highlightDateReached,
                                sortable: false,
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                key: 'endDateCampAlert',
                                label: '',
                                tdAttr: this.highlightDateReached,
                                sortable: false,
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                key: 'nbMetricsAlerts',
                                label: '',
                                tdAttr: this.highlightDateReached,
                                sortable: false,
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                key: 'budgetAlert',
                                label: '',
                                tdClass: 'objective-cell',
                                tdAttr: this.highlightDateReached,
                                sortable: false,
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                key: 'ratioAlert',
                                label: '',
                                tdClass: 'objective-cell',
                                tdAttr: this.highlightDateReached,
                                sortable: false,
                                thStyle: { textAlign: 'right' },
                            },
                            {
                                key: 'actionsAlerts',
                                label: '',
                                sortable: false,
                            },
                        ],
                    },
                };
            },
        },

        methods: {
            updateStatusFilters(filters) {
                this.$store.dispatch('app/updateFilterStatus', filters);
            },

            async linkProvider(media) {
                const dsp = await axios.get(process.env.VUE_APP_APIURL + '/metrics/api/dspProviders/');

                this.dspOptions = dsp.data;

                this.selectedCampaign = media.campaign_code;
                this.brand = media.brand;
                this.media = media.media_support_code ? media.media_support_code : media.details.media_category;
                this.salePoint = media.city ? media.city : media.details.city;
                this.salePointCode = media.point_code ? media.point_code : media.details.point_code;
                this.id = media.details?.provider_data?.id;
                this.selectedDsp = media.details.provider ? media.details.provider : '';

                if (media.type && media.type === 'unlink') {
                    this.alertType = media?.type;
                    this.alertCode = media.code;
                }

                this.$refs['modal-media-link'].open();
            },

            handleTableDataLength(length) {
                this.tableDataLength = length;
            },

            onSubmit() {
                if (!this.selectedDsp) {
                    this.isValidDsp = false;
                    return;
                }
                if (!this.id) {
                    this.isValidId = false;
                    return;
                }

                this.openAlertProvider();
            },

            isDateExceeded(date, key) {
                if (key === 'endDate') {
                    if (new Date(date.split('/').reverse()) < new Date(moment().subtract(1, 'day'))) {
                        return true;
                    }
                } else {
                    if (new Date(date.split('/').reverse()) < new Date()) {
                        return true;
                    }
                }
                return false;
            },

            highlightDateReached(value, key, item) {
                let style = {
                    style: '',
                };
                if ((item.status === 0 && key === 'startDate') || ([1, 5].includes(item.status) && key === 'endDate')) {
                    if (this.isDateExceeded(value, key)) {
                        style.style = 'color: red;';
                    }
                }
                return style;
            },

            updateFrontCampaignData(data) {
                for (let camp of data.campaigns) {
                    let modifiedCampaign = this.campaigns.find((TableCamp) => TableCamp.campaignCode === camp.campaign_code);
                    if (modifiedCampaign) {
                        modifiedCampaign.startDate = this.formatToDate(camp.start_date).toLocaleDateString('en-GB');
                        modifiedCampaign.endDate = this.formatToDate(camp.end_date).toLocaleDateString('en-GB');
                    }
                }
            },
            updateDateCampaign(campaignsDatesToUpdate) {
                this.$root.toggleGlobalLoading(true);
                let data = {
                    campaigns: campaignsDatesToUpdate,
                };

                this.$store
                    .dispatch('app/updateCampaignDate', data)
                    .then((res) => {
                        this.$emit('on-refresh');
                        this.$refs['update-date-modal'].close();
                        this.updateFrontCampaignData(data);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Date(s) modifiée avec succès',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la modification des dates',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            bannerDownload(camp) {
                // TODO
                console.warn('bannerDownload A TESTER SUR CAMPAIGN AVEC STATUS 1 OU 0');
                this.$root.toggleGlobalLoading(true);
                var data = {
                    code: camp.campaignCode ? camp.campaignCode : camp.campaign_code,
                    pointCode: camp.pointText ? camp.pointText : '',
                    theme: '',
                };

                this.$store.dispatch('app/downloadBanner', data).then((res) => {
                    this.$root.toggleGlobalLoading(false);
                    if (res.data.length > 0) {
                        for (const url of res.data) {
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.target = '_blank';
                            fileLink.setAttribute('download', camp.pointText);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        }

                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'La bannière a été téléchargée',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    } else {
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Pas de bannière à télécharger',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    }
                });
            },
            async updateMediaDates(startDate, endDateRaw, code, parent, isAlert = null, alertCode = null) {
                this.parentData = this.allCampaigns.find((camp) => camp.campaign_code === parent);
                await this.$refs['update-date-modal'].openMediaModal(startDate, endDateRaw, code, this.parentData, isAlert ? 'true' : null, alertCode ? alertCode : null);
            },

            updateDatesCamp(startDate, endDateRaw, code) {
                this.$refs['update-date-modal'].open(startDate, endDateRaw, code);
            },
            addThemeToCampaign(startDate, endDateRaw, code) {
                this.$refs['add-campaign-modal'].open(startDate, endDateRaw, code);
            },

            onOpenAlert(color, campaignCode, campaignReportNotSent) {
                switch (color) {
                    case 'success':
                        this.openAlert('Êtes-vous sûr de vouloir valider la campagne ?', this.acceptAlert, campaignCode, color, campaignReportNotSent ? campaignReportNotSent : false);
                        break;
                    case 'primary':
                        this.openAlert('Êtes-vous sûr de vouloir lancer le paiement / archiver la campagne ?', this.payAlert, campaignCode, color);
                        break;
                    case 'danger':
                        this.openAlert('Êtes-vous sûr de vouloir refuser la campagne ?', this.refuseAlert, campaignCode, color);
                        break;
                }
            },

            openAlert(msg, callback, campaignCode, color, campaignReportNotSent) {
                this.$bvModal
                    .msgBoxConfirm(msg, {
                        title: 'Veuillez confirmer',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: color,
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        if (confirmed) callback(campaignCode, campaignReportNotSent ? campaignReportNotSent : false);
                    })
                    .catch((err) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la validation de la campagne',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },

            openAlertProvider() {
                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir lier les médias?', {
                        title: 'Confirmer',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'OUI',
                        cancelTitle: 'NON',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })

                    .then((confirmed) => {
                        if (confirmed) {
                            this.linkCampaign(this.selectedCampaign, this.media, this.selectedDsp, this.id, this.alertType ? this.alertType : null, this.alertCode ? this.alertCode : null);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            async linkCampaign(campaignCode, media, dsp, id, alertType, alertCode) {
                try {
                    this.$root.toggleGlobalLoading(true);

                    const campaignData = {
                        provider: dsp,
                        media,
                        id,
                    };

                    await this.$store.dispatch('app/linkCampaign', {
                        code: campaignCode,
                        data: campaignData,
                    });

                    this.$emit('on-refresh');

                    this.$toast(this.successToast('Le média a été lié avec succès'));

                    if (this.alertType === 'unlink') {
                        await this.$store.dispatch('app/deleteAlert', {
                            code: alertCode,
                        });
                    }
                } catch (error) {
                    console.error('Error occurred in linkCampaign :', error);
                    this.$toast(this.errorToast("Le média n'a pas pu être lié"));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                    this.selectedDsp = '';
                    this.id = '';
                    this.isValidDsp = null;
                    this.isValidId = null;
                    this.$refs['modal-media-link'].close();
                }
            },

            downloadReport(data) {
                const campaignCode = data.campaignCode;

                const pointText = data.pointText.replaceAll(' ', '-');
                const startDate = data.startDate.replaceAll('/', '-');
                const brand = campaignCode.split('_')[1].toLowerCase();

                this.$root.toggleGlobalLoading(true);
                http.getPDF(this.$store.state.app.apiCampaignUrl + '/api/v1/' + brand + '/campaign/' + campaignCode + '/metrics', localStorage.getItem('accessToken'))
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        if (res[0].data.byteLength > 0) {
                            const blob = new Blob([res[0].data], {
                                type: res[0].headers['content-type'],
                            });

                            FileSaver.saveAs(blob, pointText + '_' + startDate + '_bilan.pdf');
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Le rapport a été téléchargé',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        } else {
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Pas de rapport à télécharger',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la génération du rapport',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },

            async acceptAlert(code, campaignReportNotSent = false) {
                try {
                    this.$root.toggleGlobalLoading(true);

                    await this.$store.dispatch('app/modifiedCampaigns', true);

                    let data = {
                        status: 'accept',
                        ...(campaignReportNotSent && { campaignReportNotSent: true }),
                    };

                    await this.$store.dispatch('app/updateCampaign', { code, data });

                    this.$toast(this.successToast('La campagne a été validée'));
                } catch (error) {
                    this.$toast(this.errorToast('Erreur lors de la validation de la campagne'));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                    this.$emit('on-refresh');
                }
            },

            payAlert(code) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    status: 'pay',
                };
                this.$store
                    .dispatch('app/updateCampaign', {
                        code,
                        data,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('La campagne à été payée / archivée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du paiement de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            refuseAlert(code) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    status: 'reject',
                };
                this.$store
                    .dispatch('app/updateCampaign', {
                        code,
                        data,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('La campagne à bien été refusée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du rejet de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            async openChangeProviderId(isParentStatus, code, media) {
                const found = this.globalMedias.find((e) => e.media === media);

                this.providerId = found.provider;
                this.currentCampaignCode = code;

                // await this.$store.dispatch('app/modifiedMedias', true);

                this.$refs['modal-provider-id'].open();
            },

            closeChangeProviderId() {
                this.currentMedias = [];
                this.providerId = null;
                this.$refs['modal-provider-id'].close();
            },

            openChangeMediaId(media) {
                if (Array.isArray(media) === true) {
                    this.currentMedias = media;
                    this.$refs['modal-provider-id'].open();
                } else {
                    this.providerId = media.details.provider_data.id;
                    this.currentCampaignCode = media.campaign_code;
                    this.$refs['modal-provider-id'].open();
                }
            },
            cancelCampaign() {
                this.$root.toggleGlobalLoading(true);

                this.$store
                    .dispatch('app/updateCampaignStatus', {
                        code: this.currentCampaignCode,
                        status: 99,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('La campagne a bien été annulée.'));
                        this.$refs['modal-cancel-campaign'].close();
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la mise de la campagne'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            async changeProviderId() {
                this.$root.toggleGlobalLoading(true);

                try {
                    if (this.currentMedias.length > 0) {
                        for (let index = 0; index < this.currentMedias.length; index++) {
                            const element = this.currentMedias[index];

                            await this.$store.dispatch('app/changeProviderId', {
                                code: element.campaign_code,
                                id: element.details.provider_data.id,
                            });
                        }
                    } else {
                        await this.$store.dispatch('app/changeProviderId', {
                            code: this.currentCampaignCode,
                            id: this.providerId,
                        });
                    }
                    await this.$store.dispatch('app/modifiedMedias', true);
                    this.$emit('on-refresh');

                    this.$toast(this.successToast('ID mis à jour'));

                    this.$refs['modal-provider-id'].close();
                } catch (e) {
                    console.error(e);
                    this.$toast(this.errorToast("Erreur lors de la mise à jour de l'id"));
                }

                this.$root.toggleGlobalLoading(false);
            },
            completeTopTen(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/completeTopTen', {
                        code,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('Top ten mis à jour'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la mise à jour des top ten'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            async syncMetrics(code, isAlert = false, alertCode = null) {
                try {
                    this.$root.toggleGlobalLoading(true);

                    await this.$store.dispatch('app/syncMetrics', { code });

                    this.$emit('on-refresh');
                    this.$toast(this.successToast('Synchronisation des métriques effectuée'));
                } catch (error) {
                    console.error(error);
                    this.$toast(this.errorToast('Erreur lors de la synchronisation des métriques'));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                }
            },

            forceMetrics(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/forceMetrics', {
                        code,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('Les metrics ont été forcées'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors du forcing des metrics'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            fillWithZeroMetrics(code) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/fillWithZeroMetrics', {
                        code,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('Mise à jour des metrics effectuée'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la mise à jour des metrics'));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            providerCampaignCreation(campaignCode) {
                this.$refs['create-provider-campaign-modal'].open(campaignCode);
            },
            providerMediaCreation(campaignCode, childCampaignCode) {
                this.$refs['create-provider-media-modal'].open(campaignCode, childCampaignCode);
            },
            providerFacebookCreation(campaignCode, childCampaignCode) {
                this.$refs['create-provider-facebook-modal'].open(campaignCode, childCampaignCode);
            },
            cancelCampaignAction(campaignCode) {
                this.currentCampaignCode = campaignCode;
                this.$refs['modal-cancel-campaign'].open(campaignCode);
            },

            updateParentStatus(campaignCode) {
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/updateParentStatus', {
                        campaignCode,
                    })
                    .then(() => {
                        this.$emit('on-refresh');
                        this.$toast(this.successToast('Actualisation du statut effectuée.'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast("Erreur lors de l'actualisation du statut."));
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            formatDate(dateString) {
                try {
                    const [day, month, year] = dateString.split('/');
                    const date = new Date(`${year}-${month}-${day}`);

                    if (isNaN(date)) {
                        return 'Date invalide';
                    }

                    const formattedDate = date.toLocaleDateString('fr-FR', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    });

                    const parts = formattedDate.split(' ');

                    parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

                    return parts.join(' ');
                } catch (error) {
                    return 'Date invalide';
                }
            },
        },

        beforeMount() {
            if (this.statusSubFilter) {
                this.statusSubFilters = this.statusSubFilter;
            }
        },
    };
</script>

<style lang="scss">
    th {
        font-size: 9px !important;
        font-weight: 700;
        white-space: nowrap;
    }

    .cell-status {
        max-width: 200px;

        .badge {
            white-space: normal;
        }
    }

    .pad-0 {
        padding: 0 !important;
    }

    .red {
        color: red;
    }

    .align-items-center {
        align-items: center;
    }

    .i-flex {
        display: inline-flex;
    }

    .my-form-input::placeholder {
        font-style: italic !important;
    }

    .b-table-details {
        td {
            padding: 0.72rem 2rem;
            cursor: auto;
            text-align: end;
        }
        > td {
            padding: 0;
        }

        th {
            padding-top: 0px;
            padding-bottom: 0px;
            overflow: hidden;
            div {
                height: 0;
            }
        }
    }
    .campaigns-container {
        input.form-control {
            width: 240px;

            &::placeholder {
                font-style: italic !important;
                font-size: 13px;
                transform: none !important;
            }
        }

        .card-body {
            padding-top: 1rem;
            padding-right: 0rem;
            padding-bottom: 1rem;
            padding-left: 0rem;
        }

        #checkbox-group-1 {
            .custom-checkbox {
                margin-right: 20px;
            }
        }

        .custom-control-label,
        label,
        .custom-select {
            font-size: 13px;
        }

        .b-table-empty-row {
            td {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }

        .b-skeleton {
            width: 100% !important;
            height: 2rem;
            margin-top: 1rem;
        }
    }

    .objective-cell {
        padding: 0 !important;

        div.flex {
            justify-content: flex-end;
        }
    }

    .hoverable-container:hover .hoverable-svg path {
        stroke: #7367f0;
    }

    .hoverable-svg path {
        stroke: #b4b7bd;
    }

    .dark-layout .modal .modal-header .close {
        text-shadow: none;
        box-shadow: none;
    }

    .modal .modal-header .close {
        padding: 0;
        border: none;
        position: absolute;
        right: 29px;
        top: 14px;
        box-shadow: none;
    }

    .modal .modal-header .modal .modal-header {
        display: flex;
    }

    .custom-label label {
        font-size: 13px;
    }

    .custom-date {
        text-align: right;
        font-size: 13px;
        font-weight: 500;
        padding-right: 0;
    }

    .media-content {
        max-width: 300px;
        overflow: hidden;
        position: relative;

        ::before {
            width: 10px;
            background-color: #f63b51;
        }

        .data-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .data-city {
            white-space: nowrap;
        }
    }

    .btn-lg {
        padding: 0 0 0 16px !important;
    }

    .dark-layout .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
        vertical-align: middle;
    }

    .no-header thead {
        display: none;
    }

    tbody tr td {
        padding-left: 0 !important;
        padding-right: 0.5rem !important;
        margin-left: 15px !important;

        &.media-content {
            height: 80px !important;

            &.alerts-content {
                height: auto !important;
            }
        }
    }

    .card table tr[class*='table-']:last-child td:last-child {
        border-bottom-right-radius: 0.357rem;
    }

    .dark-layout .b-dropdown.btn-group#dropdown-filter {
        button {
            background-color: transparent !important;

            border-color: #3b4253 !important;
            border-left-color: #3b4253 !important;
            border-right-color: #3b4253 !important;
            color: #b4b7bd !important;
            font-size: 13px;
            box-shadow: none !important;
        }

        ul {
            border-color: #3b4253 !important;
        }
    }
    .b-dropdown.btn-group#dropdown-filter {
        button {
            background-color: transparent !important;

            border-color: #d8d6de !important;
            border-left-color: #d8d6de !important;
            border-right-color: #d8d6de !important;
            color: #6e6b7b !important;
            font-size: 13px;
            box-shadow: none !important;
        }

        ul {
            border-color: #d8d6de !important;
        }
    }

    .dropdown-toggle.btn-sm::after,
    [dir] .btn-group-sm > .dropdown-toggle.btn::after {
        display: none;
    }

    .no-thead thead {
        display: none !important;
    }

    .brand-alert {
        margin-left: 0 !important;
        padding: 0 !important;
    }

    .media-dropdown {
        width: 150px;
        margin-left: 10px;
        margin-right: 10px;

        .bv-no-focus-ring {
            font-size: 13px !important;
            width: 200px;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .checkbox-group-custom {
        display: flex;
        align-items: center;
    }
</style>
