<template>
    <b-row class="notification-center">
        <b-card class="notification-card">
            <span class="card-title">Centre de notification</span>
            <div class="management-content">
                <div
                    v-for="(item, index) in statusAlerts"
                    :key="index"
                    @click="handleStatusClick(item.label, item.code)"
                    class="status card"
                    :class="{ 'selected-status': status.includes(item.code) }"
                >
                    <div>
                        <div class="count">{{ alerts.length }}</div>
                        <div class="status-txt">{{ item.label }}</div>
                    </div>
                </div>
            </div>
        </b-card>

        <b-card class="media-management-card">
            <b-row class="management-row">
                <b-col class="management-col">
                    <b-card>
                        <span class="card-title">Gestion par médias</span>
                        <div class="management-content">
                            <div
                                v-for="(item, index) in statusMedias"
                                :key="index"
                                @click="handleStatusClick(item.label, item.code)"
                                class="status card"
                                :class="{ 'selected-status': status.includes(item.code) }"
                            >
                                <div>
                                    <div class="alert-container">
                                        <div class="count">{{ item.count }}</div>
                                        <div v-if="item.alertCount > 0" class="alert-count">
                                            {{ item.alertCount }}
                                        </div>
                                    </div>
                                    <div class="status-txt">{{ item.label }}</div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>

                <div class="vertical-divider"></div>

                <b-col class="management-col" style="flex-grow: 2">
                    <b-card>
                        <div style="display: flex; justify-content: space-between">
                            <span class="card-title">Gestion par campagnes</span>
                            <div style="cursor: pointer" @click="showArchivedCampaigns('30')">
                                <span class="archive">Historique</span>
                                <img src="@/assets/images/icons/array-right-icon.svg" alt="Show" style="margin: auto; padding-left: 0.5rem" />
                            </div>
                        </div>
                        <div class="management-content">
                            <div
                                v-for="(camp, index) in statusCampaigns"
                                :key="index"
                                @click="handleStatusClick(camp.label, camp.code)"
                                class="status card"
                                :class="{ 'selected-status': status.includes(camp.code) }"
                            >
                                <div>
                                    <div class="alert-container">
                                        <div class="count">{{ camp.count }}</div>
                                        <div v-if="camp.alertCount > 0" class="alert-count">
                                            {{ camp.alertCount }}
                                        </div>
                                    </div>
                                    <div class="status-txt">{{ camp.label }}</div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
    </b-row>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'filters',
        props: {
            campaigns: {
                type: Array,
                required: true,
            },
            medias: {
                type: Array,
                required: true,
            },
            alerts: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                statusCampaigns: [
                    { label: 'Bilan à réaliser', code: '7', count: 0, alertCount: 0 },
                    { label: 'A valider', code: '8', count: 0, alertCount: 0 },
                    { label: 'A payer', code: '10', count: 0, alertCount: 0 },
                    { label: 'Payée', code: '20', count: 0, alertCount: 0 },
                ],

                statusMedias: [
                    { label: 'Non commencées', code: '0', count: 0, alertCount: 0 },
                    { label: 'En cours', code: '1', count: 0, alertCount: 0 },
                ],
                statusAlerts: [{ label: 'Alertes', code: '999', count: 0 }],
            };
        },
        computed: {
            ...mapGetters({
                status: 'app/getStatusFilters',
                globalAlerts: 'app/getGlobalAlerts',
            }),
        },
        mounted() {
            this.getCampaignsByStatus();
            this.getMediasByStatus();
        },
        watch: {
            campaigns: {
                handler() {
                    this.getCampaignsByStatus();
                },
                deep: true,
            },

            medias: {
                handler() {
                    this.getMediasByStatus();
                },
                deep: true,
            },
        },
        methods: {
            allstatus() {
                this.$emit('on-select-all-status');
            },

            nonestatus() {
                this.$emit('on-select-none-status');
            },

            getCampaignsByStatus() {
                this.resetStatusCampaigns();
                for (const campaign of this.campaigns) {
                    const status = campaign.status;

                    if (campaign.value === 'csm' || campaign.value === 'direction') {
                        campaign.status = 8;
                    }

                    const statusItem = this.statusCampaigns.find((item) => String(status) === item.code);

                    if (statusItem) {
                        statusItem.count++;

                        const isAlert = this.globalAlerts.some((item) => item.campaign_code === campaign.campaign_code);
                        if (isAlert) {
                            statusItem.alertCount++;
                        }
                    }

                    const alertCode = campaign.alert === 1 ? 'alert' : null;

                    const alertItem = this.statusCampaigns.find((item) => alertCode === item.code);
                    if (alertItem) {
                        alertItem.count++;
                    }
                }
            },

            getAlerts() {
                for (const alert of this.globalAlerts) {
                    if (alert.status === 0) {
                        this.unstartedMediasCountAlert++;
                    } else if (alert.status === 1) {
                        this.mediaInProgressCountAlert++;
                    } else if (alert.status === 7) {
                        this.campaignReportCountAlert++;
                    } else if (alert.status === 8) {
                        this.campaignCSMAlert++;
                    } else if (alert.status === 9) {
                        this.campaignDirectionAlert++;
                    }
                }
            },

            getMediasByStatus() {
                this.resetStatusMedias();
                const today = moment();

                for (const media of this.medias) {
                    if (((status === 0 || status === 1) && moment(media.start_date).isAfter(today)) || moment(media.start_date).isSameOrAfter(today)) {
                        // Non commencée
                        media.status = 0;
                    }

                    let status = media.status;

                    const statusMedias = this.statusMedias.find((item) => String(status) === item.code);
                    if (statusMedias) {
                        statusMedias.count++;

                        const isAlert = this.globalAlerts.some((item) => item.campaign_code === media.campaign_code);
                        if (isAlert) {
                            statusMedias.alertCount++;
                        }
                    }
                }
            },

            handleStatusClick(selectedStatus, statusCode) {
                this.statusCampaigns.find((item) => item.label === selectedStatus);

                this.$emit('on-filter-by-status', statusCode);
            },

            showArchivedCampaigns(code) {
                this.$store;

                this.$emit('on-filter-by-status', code);
            },

            resetStatusCampaigns() {
                this.statusCampaigns = [
                    { label: 'Bilan à réaliser', code: '7', count: 0, alertCount: 0 },
                    { label: 'A valider', code: '8', count: 0, alertCount: 0 },
                    { label: 'A payer', code: '10', count: 0, alertCount: 0 },
                    { label: 'Payée', code: '20', count: 0, alertCount: 0 },
                ];
            },

            resetStatusMedias() {
                this.statusMedias = [
                    { label: 'Non commencées', code: '0', count: 0, alertCount: 0 },
                    { label: 'En cours', code: '1', count: 0, alertCount: 0 },
                ];
            },
        },
    };
</script>

<style scoped lang="scss">
    .notification-center {
        gap: 15px;
        display: flex;
        margin: 0;
    }

    .notification-card {
        // padding: 12px 16px;
        flex-grow: 1;
    }

    .card {
        margin-bottom: 0;

        .management-col .card-body {
            padding: 0;
        }

        .status {
            min-width: fit-content;
        }
    }
    .media-management-card {
        // padding: 12px 16px;
        flex-grow: 3;
    }

    .status.card {
        width: 100%;
        padding: 8px 16px 8px 12px;
    }
    .card-body {
        padding: 12px 16px 16px 16px;
    }

    .management-col {
        &.media {
            flex-grow: 1;
        }

        &.campaign {
            flex-grow: 3;
        }
    }

    .card-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
    }

    .archive {
        font-size: 11px;
        color: #4b465c;
        font-weight: 400;
        margin: 0;
    }

    .dark-layout .archive {
        color: #b4b7bd;
    }

    .notification-content,
    .management-content {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin-top: 8px;
    }

    .notification-item,
    .management-item {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 16px;
    }

    .vertical-divider {
        width: 1px;
        height: 80px;
        background-color: #dbdade;
        margin: auto;
    }
    .container {
        display: flex;
        width: 100%;
        min-width: 100%;
        flex-wrap: wrap;
        gap: 15px;
    }
    .status-txt {
        font-size: 13px;
        color: #93909d;
    }

    .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 190px;
        cursor: pointer;
        height: 48px !important;
        border-radius: 6px;
        line-height: normal !important;

        &.selected-status {
            background: linear-gradient(91.43deg, #7569f1 0, #9c94f5 100%);
            color: white;
            line-height: normal !important;

            .status-txt {
                color: white;
            }
        }
    }

    .status.card:hover {
        background: rgb(237, 236, 238);
        transition: 0.15s ease;

        &.selected-status {
            background: linear-gradient(91.43deg, #7569f1 0, #9c94f5 100%);
            color: white;
            line-height: normal !important;
            transition: 0.15s ease;

            .status-txt {
                transition: 0.15s ease;
                color: white;
            }
        }
    }

    .dark-layout .status.card:hover {
        background: #edecee21;
        transition: 0.15s ease;
        &.selected-status {
            background: linear-gradient(91.43deg, #7569f1 0, #9c94f5 100%);
            color: white;
            line-height: normal !important;
            transition: 0.15s ease;

            .status-txt {
                transition: 0.15s ease;
                color: white;
            }
        }
    }

    .count {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
    }

    .management-content .management-item:last-child {
        margin-right: 5rem;
    }

    .alert-container {
        display: flex;
        gap: 5px;
    }

    .alert-count {
        font-size: 7px;
        font-weight: 500;
        line-height: 8px;
        color: #ffffff;
        background-color: #f63b51;
        width: 16px;
        height: 16px;
        border-radius: 999px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
